<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="outsForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('outsForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">委外合同号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="outsForm.outs_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="outsForm" :model="outsForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="工厂名称" prop="supp_abbr">
            <el-input disabled v-model="outsForm.supp_abbr" maxlength="30" show-word-limit placeholder="请填写工厂名称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="源单号" prop="pinv_no">
            <el-input disabled v-model="outsForm.pinv_no" maxlength="30" show-word-limit placeholder="请填写源单号"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户订单号" prop="scon_cust_no">
            <el-input v-model="outsForm.scon_cust_no" maxlength="30" show-word-limit placeholder="请填写客户订单号"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="委托数量" prop="outs_num">
            <el-input v-model="outsForm.outs_num" disabled placeholder="请填写委托数量"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托部门" prop="outs_dept_id">
            <el-select v-model="outsForm.outs_dept_id" size="small" placeholder="请选择委托部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="合同日期:" prop="cont_date">
            <el-date-picker v-model="outsForm.cont_date" type="date" placeholder="选择合同日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="合同金额" prop="cont_total">
            <el-input v-model="outsForm.cont_total" disabled placeholder="请填写合同金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="outsForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in currencyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交货日期:" prop="deli_date">
            <el-date-picker v-model="outsForm.deli_date" type="date" placeholder="选择交货日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="本币金额" prop="scon_rmbtotal">
            <el-input v-model="outsForm.scon_rmbtotal" disabled placeholder="请填写本币金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="ppay_rmbrate">
            <el-input
              @change="ppayRmbrateChaneg"
              v-model="outsForm.ppay_rmbrate"
              @input="outsForm.ppay_rmbrate = helper.keepTNum2(outsForm.ppay_rmbrate)"
              @blur="outsForm.ppay_rmbrate = helper.calcPrice(outsForm.ppay_rmbrate, 4, 100)"
              maxlength="7"
              show-word-limit
              placeholder="请填写汇率"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="经办人:" prop="cust_stff_aid">
            <el-select filterable v-model="outsForm.cust_stff_aid" placeholder="请选择经办人" size="small" clearable>
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input v-model="outsForm.cust_abbr" disabled placeholder="请填写客户简称"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="pinv_cptt_id">
            <el-select v-model="outsForm.pinv_cptt_id" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="外销经办人:" prop="cust_stff_bid">
            <el-select filterable v-model="outsForm.cust_stff_bid" placeholder="请选择外销经办人" size="small" clearable>
              <el-option v-for="item in stffUserList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="销售合同号" prop="tran_no">
            <el-input :value="helper.transverseTranNo(outsForm.tran_no)" disabled maxlength="30" placeholder="请填写销售合同号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-tabs v-model="activeName">
            <el-tab-pane label="成品信息" name="first">
              <ProdList :outsForm="outsForm"></ProdList>
            </el-tab-pane>
            <el-tab-pane label="原料信息" name="second">
              <MtrbList :outsForm="outsForm"></MtrbList>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="outsForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { outsAPI } from '@api/modules/outs';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@/api/modules/department';
import { optnAPI } from '@api/modules/optn';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import ProdList from './Component/ProdList.vue';
import MtrbList from './Component/MtrbList.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SuctEditMain',
  components: {
    editHeader,
    inputUser,
    ProdList,
    MtrbList
  },
  data() {
    return {
      rules: {},
      outsForm: {
        tran_no: ''
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      cpttList: [],
      deptList: [],
      currencyList: [],
      stffUserList: [],
      activeName: 'first'
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getOutsInfo();
      this.getCptt();
      this.initDept();
      this.getCustDport();
      this.getStffUser();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let outsForm = JSON.parse(JSON.stringify(this.outsForm));
      if (outsForm.cont_date) {
        outsForm.cont_date = parseInt(Number(new Date(outsForm.cont_date).getTime()) / 1000);
      }
      if (outsForm.deli_date) {
        outsForm.deli_date = parseInt(Number(new Date(outsForm.deli_date).getTime()) / 1000);
      }
      post(outsAPI.editOuts, outsForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 编写功能内容，从新增拿
    ppayRmbrateChaneg() {
      let totalA = new BigNumber(1);
      totalA = totalA.times(this.outsForm.ppay_rmbrate ? this.outsForm.ppay_rmbrate : 0).times(this.outsForm.cont_total ? this.outsForm.cont_total : 0);
      totalA = totalA.toNumber();
      this.outsForm.scon_rmbtotal = this.helper.haveFour(totalA + '');
    },
    // 获取部门
    initDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.currencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getOutsInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(outsAPI.getOutsById, { outs_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.outsForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.outsForm.stff_name;
            this.stffForm.dept_name = this.outsForm.dept_name;
            this.stffForm.dept_team_name = this.outsForm.dept_team_name;
            this.stffForm.user_id = this.outsForm.user_id;
            this.stffForm.dept_id = this.outsForm.dept_id;
            this.stffForm.stff_id = this.outsForm.stff_id;
            this.calcChange();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算 一些保留四位或者更改时间的位数
    calcChange() {
      if (this.outsForm.cont_date) {
        this.outsForm.cont_date = Number(this.outsForm.cont_date) * 1000;
      } else {
        this.outsForm.cont_date = null;
      }
      if (this.outsForm.deli_date) {
        this.outsForm.deli_date = Number(this.outsForm.deli_date) * 1000;
      } else {
        this.outsForm.deli_date = null;
      }
      if (this.outsForm.outs_dept_id === 0) {
        this.outsForm.outs_dept_id = null;
      }
      this.outsForm.ppay_rmbrate = this.helper.haveFour(this.outsForm.ppay_rmbrate);
      let totalA = new BigNumber(0);
      for (let i = 0; i < this.outsForm.outs_subc_list.length; i++) {
        totalA = totalA.plus(this.outsForm.outs_subc_list[i].pinv_atnweight ? this.outsForm.outs_subc_list[i].pinv_atnweight : 0);
        this.outsForm.outs_subc_list[i].pinv_atnweight = this.helper.reservedDigits(this.outsForm.outs_subc_list[i].pinv_atnweight);
        this.outsForm.outs_subc_list[i].pinv_part_price = this.helper.haveFour(this.outsForm.outs_subc_list[i].pinv_part_price);
        this.outsForm.outs_subc_list[i].pinv_invtotal = this.helper.haveFour(this.outsForm.outs_subc_list[i].pinv_invtotal);
        this.outsForm.outs_subc_list[i].prod_hsinrate = this.helper.haveFour(this.outsForm.outs_subc_list[i].prod_hsinrate);
        this.outsForm.outs_subc_list[i].prod_refundtax = this.helper.haveFour(this.outsForm.outs_subc_list[i].prod_refundtax);
        this.outsForm.outs_subc_list[i].pinv_no = this.outsForm.pinv_no;
      }
      for (let i = 0; i < this.outsForm.outs_part_list.length; i++) {
        this.outsForm.outs_part_list[i].prod_ause = this.helper.reservedDigits(this.outsForm.outs_part_list[i].prod_ause);
        this.outsForm.outs_part_list[i].prod_touse = this.helper.reservedDigits(Math.round(Number(this.outsForm.outs_part_list[i].prod_totaluse) * 10) / 10);
        this.outsForm.outs_part_list[i].prod_loss = this.helper.haveFour(this.outsForm.outs_part_list[i].prod_loss);
        this.outsForm.outs_part_list[i].prod_price = this.helper.haveFour(this.outsForm.outs_part_list[i].prod_price);
        this.outsForm.outs_part_list[i].prod_totaluse = this.helper.reservedDigits(this.outsForm.outs_part_list[i].prod_totaluse);
        this.outsForm.outs_part_list[i].prod_buse = this.helper.reservedDigits(this.outsForm.outs_part_list[i].prod_buse);
        this.outsForm.outs_part_list[i].scon_cust_no = this.outsForm.scon_cust_no;
      }
      totalA = totalA.toNumber();
      this.outsForm.outs_num = this.helper.haveFour(totalA + '');
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word {
  word-wrap: break-word;
  word-break: normal;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
