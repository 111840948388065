<template>
  <div>
    <el-table :data="outsForm.outs_subc_list" border>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="我司货号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_no'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无我司货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="客户货号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_cust_no'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_cust_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无客户货号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文品名" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_hsname'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_hsname"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无中文品名"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="中文规格" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_spec'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_spec"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无中文规格"
            >
              <template slot="append">
                <el-popover placement="bottom" width="600" @show="textEnlargementShow(1, scope.$index)" trigger="click">
                  <textEnlargement
                    ref="textEnlargement"
                    :disabledFlag="true"
                    @textEnlargementChange="textEnlargementChange"
                    :textEnlargementString="textEnlargementString"
                  ></textEnlargement>
                  <span slot="reference"><el-link type="info">...</el-link></span>
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量单位" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_unit'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_unit"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无数量单位"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="订单数量" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.pinv_atnweight'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].pinv_atnweight"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无订单数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="数量" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.pinv_btnweight'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].pinv_btnweight"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无数量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="加工单价" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.pinv_part_price'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].pinv_part_price"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无加工单价"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="加工金额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.pinv_invtotal'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].pinv_invtotal"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无加工金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="增值税率" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_hsinrate'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_hsinrate"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无增值税率"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="订单号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.scon_cust_no'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].scon_cust_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无订单号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="源单号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.pinv_no'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].pinv_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无源单号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.scon_no'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].scon_no"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无销售合同号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="税额" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'outs_subc_list.' + scope.$index + '.prod_refundtax'">
            <el-input
              v-model="outsForm.outs_subc_list[scope.$index].prod_refundtax"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无税额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';

export default {
  name: 'ProdList',
  props: {
    outsForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  components: {
    textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.outsForm.outs_subc_list[val1].prod_hsname;
      } else if (val === 1) {
        this.textEnlargementString.str = this.outsForm.outs_subc_list[val1].prod_spec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.outsForm.outs_subc_list[this.textEnlargementNum].prod_hsname = val;
      } else if (this.textEnlargementFlag === 1) {
        this.outsForm.outs_subc_list[this.textEnlargementNum].prod_spec = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
