import { render, staticRenderFns } from "./SuctEditAppr.vue?vue&type=template&id=14dd2451&scoped=true&"
import script from "./SuctEditAppr.vue?vue&type=script&lang=js&"
export * from "./SuctEditAppr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14dd2451",
  null
  
)

export default component.exports